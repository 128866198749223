/* eslint-disable max-len */
import React, { useState, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';

import * as Yup from 'yup';

import backgroundnuvens from '../../assets/img/background-nuvens.png';
import logo from '../../assets/img/logo.png';
import Footer from '../../components/Footer';
import Notification from '../../components/Notification';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function SolicitacaoExclusaoConta() {
  const params = useParams();
  const notificationRef = useRef();
  const [email, setEmail] = useState(params?.email || '');
  const [description, setDescription] = useState('');
  const [success, setSuccess] = useState(false);
  const [accountNotFound, setAccountNotFound] = useState(false);
  const { setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});

  const handleSubmit = () => {
    (async () => {
      try {
        const data = {
          email,
          description,
        };

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('E-mail inválido')
            .required('Por favor informe seu email para solicitar a exclusão de sua conta'),
          description: Yup.string()
            .required('É muito importante para nós sabermos o motivo da solicitação de exclusão de conta. Por favor informe o motivo, obrigado 🙏'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        // validation passed
        const { data: response } = await api.post('/solicitationDeleteAccounts', data);

        if (response.error === 'account-not-found') {
          setAccountNotFound(true);
          setLoading(false);
          return;
        }

        setLoading(false);
        setSuccess(true);

        notificationRef.current.notify({
          message: 'Solicitação realizada com sucesso',
        });
      } catch (err) {
        console.log(err);
        setLoading(false);
        setSuccess(false);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Por favor informe seu email para solicitar a exclusão de sua conta',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Aconteceu algo inesperado e não conseguimos realizar a solicitação de exclusão da conta',
          color: 'danger',
        });
      }
    })();
  };

  const handleTryAgain = () => {
    setValidationErrors({
      email: 'Verifique se o e-mail informado está correto e tente novamente.',
    });
    setLoading(false);
    setAccountNotFound(false);
    setSuccess(false);
  };

  const renderCard = () => {
    if (accountNotFound) {
      return (
        <Card className="shadow">
          <div className="d-flex flex-column justify-content-center align-items-center p-2 p-sm-3 p-md-5 w-100">
            <i className="fas fa-exclamation-triangle text-warning animate__animated animate__zoomInUp" style={{ fontSize: '12rem' }} />
            <p className="mt-3 text-center text-warning font-weight-bold animate__animated animate__bounceInUp">Não encontramos sua conta em nosso sistema.</p>
            <p className="mt-3 text-center text-warning font-weight-bold animate__animated animate__bounceInUp">Por favor, verifique se o e-mail informado está correto e tente novamente.</p>
            <Button color="warning" onClick={handleTryAgain}>Tentar novamente</Button>
          </div>
        </Card>
      );
    }

    if (success) {
      return (
        <Card className="shadow">
          <div className="d-flex flex-column justify-content-center align-items-center p-2 p-sm-3 p-md-5 w-100">
            <i className="fa-solid fa-circle-check text-success animate__animated animate__zoomInUp" style={{ fontSize: '12rem' }} />
            <p className="mt-3 text-center text-success font-weight-bold animate__animated animate__bounceInUp">Recebemos sua solicitação de exclusão de conta.</p>
          </div>
        </Card>
      );
    }

    return (
      <Card className="shadow">
        <CardHeader tag="h4">
          Solicitar exclusão da conta
        </CardHeader>
        <CardBody>
          Deseja realmente solicitar a exclusão da conta?
          <FormGroup className="mt-3">
            <Label>Informe o email da sua conta</Label>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              invalid={!!validationErrors.email}
              onChange={({ target: { value } }) => setEmail(value)}
            />
            <FormFeedback>{validationErrors.email}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Gostaríamos de saber o motivo da sua solicitação de exclusão de conta</Label>
            <Input
              type="textarea"
              placeholder="Motivo"
              value={description}
              invalid={!!validationErrors.description}
              multiple
              rows={5}
              onChange={({ target: { value } }) => setDescription(value)}
            />
            <FormFeedback>{validationErrors.description}</FormFeedback>
          </FormGroup>
        </CardBody>
        <CardFooter tag="div" className="d-flex justify-content-end">
          <Button color="success" onClick={handleSubmit}>Confirmar</Button>
        </CardFooter>
      </Card>
    );
  };

  return (
    <Container fluid>
      <Notification ref={notificationRef} />
      <Row style={{
        backgroundImage: `url(${backgroundnuvens})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      >
        <Col tag="div" className="d-flex justify-content-between align-items-center px-2 px-sm-3 px-md-5">
          <img src={logo} alt="NIC" style={{ width: '100%', maxWidth: '150px' }} />
          <div className="d-flex align-items-center ">
            <a href="https://www.instagram.com/nic.aba/" className="text-decoration-none" target="_blank" rel="noreferrer">
              <Button className="btn-social btn-instagram btn-toggle">
                <i className="fab fa-instagram" />
                <span className="ml-2">Acessar o Instagram</span>
              </Button>
            </a>
            <a href="http://www.abanic.com.br/" className="text-decoration-none" target="_blank" rel="noreferrer">
              <Button className="btn-social btn-facebook btn-toggle">
                <i className="fab fa-facebook" />
                <span className="ml-2">Seguir o Facebook</span>
              </Button>
            </a>
          </div>
        </Col>
      </Row>
      <Row className="my-5 justify-content-center align-items-center">
        <Col xs={12} sm={8} md={6}>
          {renderCard()}
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}

export default SolicitacaoExclusaoConta;
