import React, {
  useState,
  useCallback,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import * as Yup from 'yup';

import { AppContext } from '../../contexts/app';
import { useManager } from '../../hooks/manager';
import Notification from '../Notification';

// nome anterior Especialidade - alterado para TipoDeAtendimento 17/11/2023 18:20
function TipoDeAtendimento({ onSave = () => null }, ref) {
  const notificationRef = useRef();
  const { setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});
  const { update, store } = useManager();
  const [showModal, setShowModal] = useState(false);
  const [addTableDatas, setAddTableDatas] = useState(true);
  const [id, setId] = useState('');
  const [nome, setNome] = useState('');
  const [cor, setCor] = useState('#007bff');

  useImperativeHandle(ref, () => ({
    show: (data, add = true) => {
      setValidationErrors({});
      setAddTableDatas(add);

      if (data) {
        setId(data._id);
        setNome(data.nome);
        setCor(data?.cor || '#007bff');
        setShowModal(true);
        return;
      }

      setId(null);
      setNome('');
      setCor('#007bff');
      setShowModal(true);
    },
    close: () => setShowModal(false),
  }));

  const handleSave = useCallback(() => {
    (async () => {
      try {
        const data = {
          nome,
          cor,
        };

        const schema = Yup.object().shape({
          nome: Yup.string().required('Informe o nome da especialidade'),
          cor: Yup.string().required('Informe a cor da especialidade'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (id) {
          update(id, '/especialidades', data, (updatedData) => {
            setLoading(false);
            setShowModal(false);
            onSave(updatedData);
          }, () => setLoading(false), addTableDatas);
          return;
        }

        store('/especialidades', data, (createdData) => {
          setLoading(false);
          setShowModal(false);
          onSave(createdData);
        }, () => setLoading(false), addTableDatas);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message:
              'Por favor, preencha todos os dados necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Algo inesperado aconteceu e não foi possível salvar',
          color: 'danger',
        });
      }
    })();
  }, [id, nome, cor, addTableDatas]);

  return (
    <>
      <Notification ref={notificationRef} />
      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)} centered>
        <ModalHeader>
          <strong>Tipo de Atendimento</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowModal(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Input
                type="text"
                placeholder="Nome"
                value={nome}
                invalid={!!validationErrors.nome}
                onChange={({ target: { value } }) => setNome(value)}
              />
              <FormFeedback>{validationErrors.nome}</FormFeedback>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <Input
                type="color"
                placeholder="Cor"
                value={cor}
                invalid={!!validationErrors.cor}
                onChange={({ target: { value } }) => setCor(value)}
              />
              <FormFeedback>{validationErrors.cor}</FormFeedback>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSave}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default forwardRef(TipoDeAtendimento);
