/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useCallback, useMemo, useContext, useRef } from 'react';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Fade,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import classNames from 'classnames';
import { format, parseISO, differenceInSeconds } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import * as Yup from 'yup';

import AlertModal from '../../components/AlertModal';
import CustomModal from '../../components/CustomModal';
import DatePickerMaskedInput from '../../components/DatePickerMaskedInput';
import CustomModalEmitirFatura from '../../components/Financeiro/CustomModalEmitirFatura';
import CustomModalFecharFatura from '../../components/Financeiro/CustomModalFecharFatura';
import CustomModalFecharFaturas from '../../components/Financeiro/CustomModalFecharFaturas';
import CustomModalMotivoCancelamento from '../../components/Financeiro/CustomModalMotivoCancelamento';
import CustomModalPagarFatura from '../../components/Financeiro/CustomModalPagarFatura';
import CustomModalPagarFaturas from '../../components/Financeiro/CustomModalPagarFaturas';
import FinanceiroEdit from '../../components/Financeiro/Edit';
import TableManager from '../../components/TableManager';
import { AppContext } from '../../contexts/app';
import { useManager } from '../../hooks/manager';
import useSecondsToDhms from '../../hooks/useSecondsToDhms';
import api from '../../services/api';
import { numberToCurrency } from '../../utils/functions';

function Financeiros() {
  const navigate = useNavigate();
  const alertRef = useRef();
  const modal = useRef();
  const modalFinanceiroEdit = useRef();
  const modalEmitirFatura = useRef();
  const modalFecharFatura = useRef();
  const modalFecharFaturas = useRef();
  const modalPagarFaturas = useRef();
  const modalPagarFatura = useRef();
  const modalMotivoCancelamento = useRef();
  const { convertSecondsToDhms, convertHourAndMinutesToDhms } = useSecondsToDhms();
  const { loading, setLoading, notificationRef } = useContext(AppContext);
  const {
    load,
    update,
    destroy,
    // filtered,
    setDatas,
    extraData: {
      totalContaPagar = 0,
      totalContaReceber = 0,
    },
  } = useManager();
  const [selectedRows, setSelectedRows] = useState([]);
  const [showCloseFinanceiroModal, setShowCloseFinanceiroModal] = useState(false);
  const [dataCloseFinanceiroInicio, setDataCloseFinanceiroInicio] = useState('');
  const [dataCloseFinanceiroTermino, setDataCloseFinanceiroTermino] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    load('/financeiros', 1, []);
  }, [load]);

  /*  const handleCreatePdf = useCallback((_selectedRows = []) => {
    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Aguarde... Estamos gerando o arquivo PDF... ',
        });

        // await fetch api
        const res = await api.get(`/financeiroPDFCreatorReportLists?financeirosIds=${JSON.stringify(_selectedRows.map(({ _id }) => _id))}`, { responseType: 'blob' });
        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar ao gerar relatório',
          color: 'danger',
        });
      }
    })();
  }, [setLoading]); */

  const handleCreateCsv = useCallback((_selectedRows = []) => {
    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Aguarde... Estamos gerando o arquivo CSV... ',
        });

        // await fetch api
        const res = await api.post('/financeiroCSVCreatorReportLists', { financeirosIds: _selectedRows.map(({ _id }) => _id) }, { responseType: 'blob' });
        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar ao gerar relatório',
          color: 'danger',
        });
      }
    })();
  }, [setLoading]);

  const handleChangeStatus = useCallback((row, searchArray, page = 1, limit = 10) => {
    if (row.status === 'aberto') {
      modalFecharFatura.current.show({
        id: row._id,
        agendamento: row.agendamento,
        valor: row.valor,
        searchArray,
        page,
        limit,
      });
      return;
    }

    if (row.status === 'fechado') {
      modal.current.show({
        title: 'Alterar Status',
        content: () => <span className="text-center">Escolha qual o ação deseja fazer com essa Fatura</span>,
        actions: () => (
          <Row className="w-100">
            <Col xs={6}>
              <Button
                color="info"
                className="h-100"
                onClick={() => update(row._id, '/financeiros', { status: 'aberto' }, () => modal.current.close(), null, true, searchArray, page, limit, true)}
                disabled={loading}
                block
              >
                Abrir Fatura
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                color="danger"
                className="h-100"
                onClick={() => update(row._id, '/financeiros', { status: 'emitido' }, () => modal.current.close(), null, true, searchArray, page, limit, true)}
                disabled={loading}
                block
              >
                Gerar Folha de Pagamento
              </Button>
            </Col>
          </Row>
        ),
      });
      return;
    }

    if (row.status === 'emitido') {
      alertRef.current.show({
        title: 'Reabrir Fatura',
        message: 'Deseja realmente reabrir a fatura? Esta ação vai Inativar a folha de pagamento, apagar as datas de vencimento, fechamento, emissão e alterar a situação da fatura para a vencer.',
        onConfirm: () => update(row._id, '/financeiros', { status: 'aberto' }, () => alertRef.current.close(), null, true, searchArray, page, limit, true),
      });
    }
  }, []);

  const handleChangeSituacao = useCallback((row, searchArray, page = 1, limit = 10) => {
    if (row.situacao === 'pago') return;

    if (row.situacao !== 'a-vencer') {
      alertRef.current.show({
        title: 'Alterar Situação',
        message: 'Deseja alterar a situação para a vencer?',
        onConfirm: () => update(row._id, '/financeiros', { situacao: 'a-vencer' }, () => alertRef.current.close(), null, true, searchArray, page, limit, true),
      });
    }

    modalPagarFatura.current.show({
      id: row._id,
      searchArray,
      page,
      limit,
    });
  }, []);

  const handleGerarFolhaPagamento = useCallback(() => {
    modalEmitirFatura.current.show({
      selectedRows: selectedRows.filter((selectedRow) => (selectedRow.status === 'fechado')),
    });
  }, [selectedRows]);

  const handleFecharFaturaSelecionadas = useCallback(() => {
    modalFecharFaturas.current.show({
      selectedRows: selectedRows.filter((selectedRow) => (selectedRow.status === 'aberto')),
    });
  }, [selectedRows]);

  const handlePagarFaturaSelecionadas = useCallback(() => {
    modalPagarFaturas.current.show({
      selectedRows: selectedRows.filter((selectedRow) => (selectedRow.situacao !== 'pago')),
    });
  }, [selectedRows]);

  const onAgendamentoCancelar = useCallback(({ _id, motivoCancelamento, dataCancelamento }) => {
    setDatas((datas = []) => datas.map((d) => (d?.agendamento?._id === _id ? { ...d, agendamento: { ...d.agendamento, status: 'cancelado', motivoCancelamento, dataCancelamento } } : d)));
    setLoading(false);
    notificationRef.current.notify({
      message: 'Agendamento cancelado com sucesso',
    });
  }, []);

  const handleAgendamentoCancelamento = useCallback((row) => {
    if (row?.agendamento?.status === 'aberto') {
      alertRef.current.show({
        title: 'Cancelar Atendimento',
        message: 'Deseja realmente cancelar o atendimento?',
        onConfirm: () => {
          alertRef.current.close();
          modalMotivoCancelamento.current.show({ _id: row.agendamento._id, onSave: onAgendamentoCancelar });
        },
      });
    }
  }, []);

  const handleCloseFinanceiro = useCallback(() => {
    (async () => {
      try {
        const validationData = {
          dataCloseFinanceiroInicio,
          dataCloseFinanceiroTermino,
        };

        const schema = Yup.object().shape({
          dataCloseFinanceiroInicio: Yup.string().required('Informe a data de início'),
          dataCloseFinanceiroTermino: Yup.string().required('Informe a data de término'),
        });

        await schema.validate(validationData, {
          abortEarly: false,
        });
        setLoading(true);

        const data = {
          dataInicio: dataCloseFinanceiroInicio,
          dataTermino: dataCloseFinanceiroTermino,
        };

        await api.post('/financeiroPeriodos', data);
        setLoading(false);
        notificationRef.current.notify({
          message: 'Financeiro fechado com sucesso',
        });
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Por favor, preencha todos os dados necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Algo inesperado aconteceu e não foi possível salvar',
          color: 'danger',
        });
      }
    })();
  }, [dataCloseFinanceiroInicio, dataCloseFinanceiroTermino]);

  const createdByFormatter = useCallback((row) => {
    if (row?.createdByAdmin?.name) {
      return (
        <div className="d-flex justify-content-center">
          <i className="fa-solid fa-user-gear text-success mr-2" />
          {row?.createdByAdmin?.name}
        </div>
      );
    }

    if (row?.createdByProfissional?.name) {
      return (
        <div className="d-flex justify-content-center">
          <i className="fa-solid fa-user-doctor text-danger mr-2" />
          {row?.createdByProfissional?.name}
        </div>
      );
    }

    return (
      <>
        <UncontrolledTooltip target={`createdBy-${row._id}`}>
          {`Não identificado - ID do registro ${row._id}`}
        </UncontrolledTooltip>
        <i
          id={`createdBy-${row._id}`}
          className="fa-solid fa-circle-question text-danger mt-2"
        />
      </>
    );
  }, []);

  const tipoFormatter = useCallback((row) => {
    if (row?.tipo === 'conta-pagar') {
      return (
        <Badge className="w-100" color="danger" tag="div">
          <i className="fa-solid fa-money-bill-transfer mr-2" />
          a Pagar
        </Badge>
      );
    }

    if (row?.tipo === 'conta-receber') {
      return (
        <Badge className="w-100" color="success" tag="div">
          <i className="fa-solid fa-hand-holding-dollar mr-2" />
          a Receber
        </Badge>
      );
    }

    return (
      <>
        <UncontrolledTooltip target={`tipo-${row._id}`}>
          Não Identificado
        </UncontrolledTooltip>
        <i
          id={`tipo-${row._id}`}
          className="fa-solid fa-circle-question text-danger mt-2"
        />
      </>
    );
  }, []);

  const contaDeFormatter = useCallback((row) => {
    if (row?.contaDe === 'cliente') {
      return (
        <>
          <i className="fa-solid fa-user text-success mr-2" />
          Cliente
        </>
      );
    }

    if (row?.contaDe === 'fornecedor') {
      return (
        <>
          <i className="fa-solid fa-building-user text-warning mr-2" />
          Fornecedor
        </>
      );
    }

    if (row?.contaDe === 'profissional') {
      return (
        <>
          <i className="fa-solid fa-user-tie text-danger mr-2" />
          Profissional
        </>
      );
    }

    return (
      <>
        <UncontrolledTooltip target={`contaDe-${row._id}`}>
          Não Identificado
        </UncontrolledTooltip>
        <i
          id={`contaDe-${row._id}`}
          className="fa-solid fa-circle-question text-danger mt-2"
        />
      </>
    );
  }, []);

  /* const contaTipoFormatter = useCallback((row) => {
    if (row?.contaTipo === 'normal') {
      return (
        <>
          <i className="fa-solid fa-money-check-dollar text-info mr-2" />
          Normal
        </>
      );
    }

    if (row?.contaTipo === 'reembolso') {
      return (
        <>
          <i className="fa-solid fa-money-bill-transfer text-danger mr-2" />
          Reembolso
        </>
      );
    }

    return (
      <>
        <i className="fa-solid fa-money-check-dollar text-info mr-2" />
        Normal
      </>
    );

    return (
      <>
        <UncontrolledTooltip target={`contaDe-${row._id}`}>
          Não Identificado
        </UncontrolledTooltip>
        <i
          id={`contaDe-${row._id}`}
          className="fa-solid fa-circle-question text-danger mt-2"
        />
      </>
    );
  }, []); */

  const formattedCanceladoBy = (row) => {
    const { agendamento: { motivoCancelamento, dataCancelamento, canceladoByCliente }, canceladoByAdmin, canceladoByProfissional } = row;

    if (canceladoByAdmin?.name) {
      return `Motivo do Cancelamento: ${motivoCancelamento || 'Não informado'}. Cancelado pelo Admin ${canceladoByAdmin.name} em ${format(new Date(dataCancelamento), 'dd/MM/yyyy HH:mm', { locale: ptBR })}`;
    }

    if (canceladoByProfissional?.name) {
      return `Motivo do Cancelamento: ${motivoCancelamento || 'Não informado'}. Cancelado pelo Profissional ${canceladoByProfissional.name} em ${format(new Date(dataCancelamento), 'dd/MM/yyyy HH:mm', { locale: ptBR })}`;
    }

    if (canceladoByCliente?.nome) {
      return `Motivo do Cancelamento: ${motivoCancelamento || 'Não informado'}. Cancelado pelo Cliente ${canceladoByCliente.nome} em ${format(new Date(dataCancelamento), 'dd/MM/yyyy HH:mm', { locale: ptBR })}`;
    }

    return 'Não Identificado';
  };

  const atendimentoFormatter = useCallback((row) => {
    if (row?.agendamento?.status === 'aberto') {
      return (
        <span className="d-flex w-100 justify-content-between align-items-center">
          <UncontrolledTooltip target={`agendamento-${row._id}`}>
            Clique para Cancelar.
          </UncontrolledTooltip>
          <span id={`agendamento-${row._id}`}>
            <i className="fa-solid fa-circle text-info mr-2" />
            Em Aberto
          </span>
          <i className="ml-2 fa-solid fa-pen text-primary" />
        </span>
      );
    }

    if (row?.agendamento?.status === 'cancelado') {
      return (
        <span className="d-flex w-100 justify-content-between align-items-center">
          <UncontrolledTooltip target={`agendamento-${row._id}`}>
            {formattedCanceladoBy(row)}
          </UncontrolledTooltip>
          <span id={`agendamento-${row._id}`}>
            <i className="fa-solid fa-calendar-xmark text-danger mr-2" />
            Cancelado
          </span>
          <UncontrolledTooltip target={`agendamento-eye-${row._id}`}>
            {formattedCanceladoBy(row)}
          </UncontrolledTooltip>
          <i id={`agendamento-eye-${row._id}`} className="ml-2 fa-solid fa-eye text-primary" />
        </span>
      );
    }

    if (row?.agendamento?.status === 'concluido') {
      return (
        <span className="d-flex w-100 justify-content-between align-items-center">
          <span>
            <i className="fa-solid fa-circle-check text-success mr-2" />
            Concluido
          </span>
          <i className="ml-2 fa-solid fa-pen text-transparent" />
        </span>
      );
    }

    return (
      <span className="d-flex w-100 justify-content-between align-items-center">
        <span>
          <i className="fa-solid fa-exclamation-circle text-secondary mr-2" />
          Sem agendamento
        </span>
        <i className="ml-2 fa-solid fa-pen text-transparent" />
      </span>
    );
  }, []);

  const statusFormatter = useCallback((row) => {
    if (row?.agendamento?.status === 'cancelado') {
      return (
        <span className="d-flex w-100 justify-content-between align-items-center">
          <UncontrolledTooltip target={`agendamento-${row._id}`}>
            {formattedCanceladoBy(row)}
          </UncontrolledTooltip>
          <span id={`agendamento-${row._id}`}>
            <i className="fa-solid fa-calendar-xmark text-danger mr-2" />
            Cancelado
          </span>
          <UncontrolledTooltip target={`agendamento-eye-${row._id}`}>
            {formattedCanceladoBy(row)}
          </UncontrolledTooltip>
          <i id={`agendamento-eye-${row._id}`} className="ml-2 fa-solid fa-eye text-primary" />
        </span>
      );
    }

    if (row?.status === 'aberto') {
      return (
        <span className="d-flex w-100 justify-content-between align-items-center">
          <UncontrolledTooltip target={`status-${row._id}`}>
            Clique para Fechar.
          </UncontrolledTooltip>
          <span id={`status-${row._id}`}>
            <i className="fa-solid fa-circle text-success mr-2" />
            Em Aberto
          </span>
          <i className="ml-2 fa-solid fa-pen text-primary" />
        </span>
      );
    }

    if (row?.status === 'fechado') {
      if (row?.agendamento?.status === 'cancelado') {
        return (
          <span className="d-flex w-100 justify-content-between align-items-center">
            <span id={`status-${row._id}`}>
              <i className="fa-solid fa-circle text-warning mr-2" />
              Fechado
            </span>
            <i className="ml-2 fa-solid fa-pen text-transparent" />
          </span>
        );
      }
      return (
        <span className="d-flex w-100 justify-content-between align-items-center">
          <UncontrolledTooltip target={`status-${row._id}`}>
            Clique para Alterar.
          </UncontrolledTooltip>
          <span id={`status-${row._id}`}>
            <i className="fa-solid fa-circle text-warning mr-2" />
            Fechado
          </span>
          <i className="ml-2 fa-solid fa-pen text-primary" />
        </span>
      );
    }

    if (row?.status === 'emitido') {
      return (
        <span className="d-flex w-100 justify-content-between align-items-center">
          <UncontrolledTooltip target={`status-${row._id}`}>
            Clique para Reabrir.
          </UncontrolledTooltip>
          <span id={`status-${row._id}`}>
            <i className="fa-solid fa-circle text-danger mr-2" />
            Emitido
          </span>
          <i className="ml-2 fa-solid fa-pen text-primary" />
        </span>
      );
    }

    return (
      <>
        <UncontrolledTooltip target={`status-${row._id}`}>
          Não Identificado
        </UncontrolledTooltip>
        <i
          id={`status-${row._id}`}
          className="fa-solid fa-circle-question text-danger mt-2"
        />
      </>
    );
  }, []);

  const folhaPagamentoHumanReadableFormatter = useCallback((row) => {
    if (row?.contaDe === 'fornecedor') {
      return (
        <>
          <i className="fa-solid fa-building-user text-success mr-2" />
          Não Emite
        </>
      );
    }

    if (!row?.folhaPagamentoHumanReadable) {
      return (
        <>
          <i className="fa-solid fa-spinner text-warning mr-2" />
          Não Gerado
        </>
      );
    }

    return (
      <>
        <i className="fa-solid fa-hashtag text-danger mr-2" />
        {row?.folhaPagamentoHumanReadable}
      </>
    );
  }, []);

  const situacaoFormatter = useCallback((row) => {
    if (row?.agendamento?.status === 'cancelado') {
      return (
        <span className="d-flex w-100 justify-content-between align-items-center">
          <UncontrolledTooltip target={`agendamento-${row._id}`}>
            {formattedCanceladoBy(row)}
          </UncontrolledTooltip>
          <span id={`agendamento-${row._id}`}>
            <i className="fa-solid fa-calendar-xmark text-danger mr-2" />
            Cancelado
          </span>
          <UncontrolledTooltip target={`agendamento-eye-${row._id}`}>
            {formattedCanceladoBy(row)}
          </UncontrolledTooltip>
          <i id={`agendamento-eye-${row._id}`} className="ml-2 fa-solid fa-eye text-primary" />
        </span>
      );
    }

    if (row?.situacao === 'a-vencer') {
      return (
        <span className="d-flex w-100 justify-content-between align-items-center">
          <UncontrolledTooltip target={`situacao-${row._id}`}>
            Clique para Pagar.
          </UncontrolledTooltip>
          <span id={`situacao-${row._id}`}>
            <i className="fa-solid fa-circle-info text-info mr-2" />
            A Vencer
          </span>
          <i className="ml-2 fa-solid fa-pen text-primary" />
        </span>
      );
    }

    if (row?.situacao === 'pago') {
      return (
        <span className="d-flex w-100 justify-content-between align-items-center">
          <span id={`situacao-${row._id}`}>
            <i className="fa-solid fa-circle-check text-success mr-2" />
            Pago
          </span>
          <i className="ml-2 fa-solid fa-pen text-transparent" />
        </span>
      );
    }

    if (row?.situacao === 'vencido') {
      return (
        <span className="d-flex w-100 justify-content-between align-items-center">
          <UncontrolledTooltip target={`situacao-${row._id}`}>
            Clique para Pagar.
          </UncontrolledTooltip>
          <span id={`situacao-${row._id}`}>
            <i className="fa-solid fa-circle-xmark text-danger mr-2" />
            Vencido
          </span>
          <i className="ml-2 fa-solid fa-pen text-primary" />
        </span>
      );
    }

    return (
      <>
        <UncontrolledTooltip target={`situacao-${row._id}`}>
          Não Identificado
        </UncontrolledTooltip>
        <i
          id={`situacao-${row._id}`}
          className="fa-solid fa-circle-question text-danger mt-2"
        />
      </>
    );
  }, []);

  const grupoFormatter = useCallback((row) => {
    // console.log(row);
    if (row?.clientegrupo?.nome) {
      return (
        <>
          <i className="fa-solid fa-circle mr-2" style={{ color: row?.clientegrupo?.color ? `${row.clientegrupo.color}` : 'var(--dark)' }} />
          {row.clientegrupo.nome}
        </>
      );
    }

    if (row?.fornecedorgrupo?.nome) {
      return (
        <>
          <i className="fa-solid fa-circle mr-2" style={{ color: row?.fornecedorgrupo?.color ? `${row.fornecedorgrupo.color}` : 'var(--dark)' }} />
          {row.fornecedorgrupo.nome}
        </>
      );
    }

    if (row?.profissionalgrupo?.nome) {
      return (
        <>
          <i className="fa-solid fa-circle mr-2" style={{ color: row?.profissionalgrupo?.color ? `${row.profissionalgrupo.color}` : 'var(--dark)' }} />
          {row.profissionalgrupo.nome}
        </>
      );
    }

    if (row?.clienteUserData?.grupo?.nome) {
      return (
        <>
          <i className="fa-solid fa-circle mr-2" style={{ color: row?.clienteUserData?.grupo?.color ? `${row.clienteUserData?.grupo.color}` : 'var(--dark)' }} />
          {row.clienteUserData?.grupo.nome}
        </>
      );
    }

    if (row?.fornecedor?.grupo?.nome) {
      return (
        <>
          <i className="fa-solid fa-circle mr-2" style={{ color: row?.fornecedor?.grupo?.color ? `${row.fornecedor?.grupo.color}` : 'var(--dark)' }} />
          {row.fornecedor?.grupo.nome}
        </>
      );
    }

    if (row?.profissional?.grupo?.nome) {
      return (
        <>
          <i className="fa-solid fa-circle mr-2" style={{ color: row?.profissional?.grupo?.color ? `${row.profissional?.grupo.color}` : 'var(--dark)' }} />
          {row.profissional?.grupo.nome}
        </>
      );
    }

    return (
      <>
        <i className="fa-solid fa-exclamation-circle text-secondary mr-2" />
        Sem grupo
      </>
    );
  }, []);

  const especialistaFormatter = useCallback((row) => {
    if (row?.profissional?.name) {
      return (
        <div className="d-flex align-items-center">
          <UncontrolledTooltip target={`nome-tipo-${row._id}`}>
            Profissional
          </UncontrolledTooltip>
          <i
            id={`nome-tipo-${row._id}`}
            className="fa-solid fa-user-tie text-danger mr-2"
          />
          {row.profissional.name}
        </div>
      );
    }

    if (row?.profissionalAgendamento?.name) {
      return (
        <div className="d-flex align-items-center">
          <UncontrolledTooltip target={`nome-tipo-${row._id}`}>
            Profissional
          </UncontrolledTooltip>
          <i
            id={`nome-tipo-${row._id}`}
            className="fa-solid fa-user-tie text-danger mr-2"
          />
          {row.profissionalAgendamento.name}
        </div>
      );
    }

    return (
      <div className="d-flex align-items-center">
        -
      </div>
    );
  }, []);

  const especialidadeFormatter = useCallback((row) => {
    if (row?.especialidade?.nome) {
      return (
        <div className="d-flex align-items-center">
          <UncontrolledTooltip target={`nome-tipo-${row._id}`}>
            Especialidade
          </UncontrolledTooltip>
          <i
            id={`nome-tipo-${row._id}`}
            className="fa-solid fa-circle mr-2"
            style={{ color: row?.especialidade?.cor ? `${row.especialidade.cor}` : 'var(--blue)' }}
          />
          {row.especialidade.nome}
        </div>
      );
    }

    if (row?.especialidadeAgendamento?.nome) {
      return (
        <div className="d-flex align-items-center">
          <UncontrolledTooltip target={`nome-tipo-${row._id}`}>
            Especialidade
          </UncontrolledTooltip>
          <i
            id={`nome-tipo-${row._id}`}
            className="fa-solid fa-circle mr-2"
            style={{ color: row?.especialidadeAgendamento?.cor ? `${row.especialidadeAgendamento.cor}` : 'var(--blue)' }}
          />
          {row.especialidadeAgendamento.nome}
        </div>
      );
    }

    return (
      <div className="d-flex align-items-center">
        -
      </div>
    );
  }, []);

  const nameFormatter = useCallback((row) => {
    if (row?.contaDe === 'profissional' && row?.profissional?.name) {
      return (
        <div className="d-flex align-items-center">
          <UncontrolledTooltip target={`nome-tipo-${row._id}`}>
            Profissional
          </UncontrolledTooltip>
          <i
            id={`nome-tipo-${row._id}`}
            className="fa-solid fa-user-tie text-danger mr-2"
          />
          {row.profissional.name}
        </div>
      );
    }

    if (row?.contaDe === 'cliente' && row?.cliente?.nome) {
      return (
        <div className="d-flex align-items-center">
          <UncontrolledTooltip target={`nome-tipo-${row._id}`}>
            Cliente
          </UncontrolledTooltip>
          <i
            id={`nome-tipo-${row._id}`}
            className="fa-solid fa-user text-success mr-2"
          />
          {row.cliente.nome}
        </div>
      );
    }

    if (row?.fornecedor?.nome) {
      return (
        <div className="d-flex align-items-center">
          <UncontrolledTooltip target={`nome-tipo-${row._id}`}>
            Fornecedor
          </UncontrolledTooltip>
          <i
            id={`nome-tipo-${row._id}`}
            className="fa-solid fa-building-user text-warning mr-2"
          />
          {row.fornecedor.nome}
        </div>
      );
    }

    return (
      <>
        <UncontrolledTooltip target={`nome-tipo-${row._id}`}>
          Não Identificado
        </UncontrolledTooltip>
        <i
          id={`nome-tipo-${row._id}`}
          className="fa-solid fa-circle-question text-danger mt-2"
        />
      </>
    );
  }, []);

  const horasFormatter = useCallback((row) => {
    if (typeof row?.qtdeHoras === 'number') {
      return (
        <span className="d-flex align-items-center">
          <i className="fa-solid fa-clock text-success mr-2" />
          {convertSecondsToDhms(row.qtdeHoras * 3600)}
        </span>
      );
    }

    if (typeof row?.qtdeHoras === 'string') {
      return (
        <span className="d-flex align-items-center">
          <i className="fa-solid fa-clock text-success mr-2" />
          {convertHourAndMinutesToDhms(row.qtdeHoras)}
        </span>
      );
    }

    if (row?.agendamento?.status === 'concluido' && row?.agendamento?.dataHoraInicioAtendimento && row?.agendamento?.dataHoraTerminoAtendimento) {
      return (
        <span className="d-flex align-items-center">
          <i className="fa-solid fa-clock text-success mr-2" />
          {convertSecondsToDhms(differenceInSeconds(new Date(row.agendamento.dataHoraTerminoAtendimento), new Date(row.agendamento.dataHoraInicioAtendimento)))}
        </span>
      );
    }

    if (row?.agendamento?.dataHoraTermino && row?.agendamento?.dataHoraInicio) {
      return (
        <span className="d-flex align-items-center">
          <i className="fa-solid fa-clock text-success mr-2" />
          {convertSecondsToDhms(differenceInSeconds(new Date(row.agendamento.dataHoraTermino), new Date(row.agendamento.dataHoraInicio)))}
        </span>
      );
    }

    return (
      <span className="d-flex align-items-center">
        <i className="fa-solid fa-clock text-success mr-2" />
        -
      </span>
    );
  }, []);

  const adminFaturamentoFormatter = useCallback((row) => {
    if (row?.contaDe !== 'cliente') {
      return (
        <>
          <i className="fa-solid fa-exclamation-circle text-success mr-2" />
          Todos
        </>
      );
    }

    if (row?.adminsFaturamento) {
      const paciente = row?.user?.criancas?.find((c) => c.cpf === row.cliente.cpf) || {};

      const adminFaturamento = row.adminsFaturamento ? row.adminsFaturamento.find((s) => s._id === paciente.adminFaturamento) : '';

      if (adminFaturamento?.name) {
        return (
          <div className="d-flex align-items-center">
            <UncontrolledTooltip target={`admin-faturamento-${row._id}`}>
              Responsável Faturamento
            </UncontrolledTooltip>
            <i
              id={`admin-faturamento-${row._id}`}
              className="fa-solid fa-user-gear text-success mr-2"
            />
            {adminFaturamento.name}
          </div>
        );
      }
    }

    return (
      <>
        <i className="fa-solid fa-exclamation-circle text-secondary mr-2" />
        Sem Responsável
      </>
    );
  }, []);

  const cpfOrCnpjFormatter = useCallback((row) => {
    if (row?.profissional?.cpf) {
      return (
        <span className="d-flex align-items-center">
          <UncontrolledTooltip target={`cpf-tipo-${row._id}`}>
            Profissional
          </UncontrolledTooltip>
          <i
            id={`cpf-tipo-${row._id}`}
            className="fa-solid fa-user-tie text-danger mr-2"
          />
          <PatternFormat
            format="###.###.###-##"
            value={row.profissional.cpf}
            displayType="text"
            renderText={(formattedValue) => formattedValue}
          />
        </span>
      );
    }

    if (row?.cliente?.cpf) {
      return (
        <span className="d-flex align-items-center">
          <UncontrolledTooltip target={`cpf-tipo-${row._id}`}>
            Cliente
          </UncontrolledTooltip>
          <i
            id={`cpf-tipo-${row._id}`}
            className="fa-solid fa-user text-success mr-2"
          />
          <PatternFormat
            format="###.###.###-##"
            value={row.cliente.cpf}
            displayType="text"
            renderText={(formattedValue) => formattedValue}
          />
        </span>
      );
    }

    if (row?.fornecedor?.cnpj) {
      return (
        <span className="d-flex align-items-center">
          <UncontrolledTooltip target={`cpf-tipo-${row._id}`}>
            Fornecedor
          </UncontrolledTooltip>
          <i
            id={`cpf-tipo-${row._id}`}
            className="fa-solid fa-building-user text-warning mr-2"
          />
          <PatternFormat
            format="##.###.###/####-##"
            value={row.fornecedor.cnpj}
            displayType="text"
            renderText={(formattedValue) => formattedValue}
          />
        </span>
      );
    }

    return (
      <>
        <UncontrolledTooltip target={`cpf-tipo-${row._id}`}>
          Não Identificado
        </UncontrolledTooltip>
        <i
          id={`cpf-tipo-${row._id}`}
          className="fa-solid fa-circle-question text-danger mt-2"
        />
      </>
    );
  }, []);

  const observacaoFormatter = useCallback((row) => {
    if (row?.observacoes?.length) {
      return (
        <>
          <UncontrolledTooltip target={`observacao-eye-${row._id}`}>
            {`Última observação - ${row.observacoes[row.observacoes.length - 1].observacao}`}
          </UncontrolledTooltip>
          <i id={`observacao-eye-${row._id}`} className="ml-2 fa-solid fa-eye text-primary" />
        </>
      );
    }

    return null;
  }, []);

  // tipo de modulo
  const tipoFilter = useMemo(
    () => [
      {
        value: 'conta-receber',
        label: 'a Receber',
      },
      {
        value: 'conta-pagar',
        label: 'a Pagar',
      },
    ],
    [],
  );

  // tipo de conta
  const contaDeFilter = useMemo(
    () => [
      {
        value: 'cliente',
        label: 'Cliente',
      },
      {
        value: 'fornecedor',
        label: 'Fornecedor',
      },
      {
        value: 'profissional',
        label: 'Profissional',
      },
    ],
    [],
  );

  /* const contaTipoFilter = useMemo(
    () => [
      {
        value: 'normal',
        label: 'Normal',
      },
      {
        value: 'reembolso',
        label: 'Reembolso',
      },
    ],
    [],
  ); */

  const atendimentoFilter = useMemo(
    () => [
      {
        value: 'aberto',
        label: 'Em aberto',
      },
      {
        value: 'cancelado',
        label: 'Cancelado',
      },
      {
        value: 'concluido',
        label: 'Concluido',
      },
      {
        value: 'sem-agendamento',
        label: 'Sem agendamento',
      },
    ],
    [],
  );

  const statusFilter = useMemo(
    () => [
      {
        value: 'aberto',
        label: 'Em aberto',
      },
      {
        value: 'fechado',
        label: 'Fechado',
      },
      {
        value: 'emitido',
        label: 'Emitido',
      },
    ],
    [],
  );

  const situacaoFilter = useMemo(
    () => [
      {
        value: 'a-vencer',
        label: 'A Vencer',
      },
      {
        value: 'pago',
        label: 'Pago',
      },
      {
        value: 'vencido',
        label: 'Vencido',
      },
    ],
    [],
  );

  const columns = useMemo(
    () => [
      {
        dataField: 'createdBy',
        text: 'Lancado Por',
        textFilter: true,
        formatter: createdByFormatter,
        className: 'text-center',
      },
      {
        dataField: 'tipo',
        text: 'Módulo',
        selectFilter: tipoFilter,
        formatter: tipoFormatter,
        className: 'text-center',
      },
      {
        dataField: 'contaDe',
        text: 'Tipo',
        selectFilter: contaDeFilter,
        formatter: contaDeFormatter,
      },
      /* {
        dataField: 'contaTipo',
        text: 'Tipo Conta',
        selectFilter: contaTipoFilter,
        formatter: contaTipoFormatter,
      }, */
      {
        dataField: 'atendimento',
        text: 'Atendimento',
        selectFilter: atendimentoFilter,
        formatter: atendimentoFormatter,
        className: 'text-center',
        onClick: (row) => handleAgendamentoCancelamento(row),
      },
      {
        dataField: 'dataAtendimento',
        text: 'Datas dos Atendimentos',
        formatter: (row) => (row.agendamento ? row.agendamento.dataHoraInicio ? format(parseISO(row.agendamento.dataHoraInicio), 'dd/MM/yyyy HH:mm') : '-' : 'Sem Agendamento'),
        dateFilter: true,
        className: 'text-center',
      },
      {
        dataField: 'status',
        text: 'Fatura',
        selectFilter: statusFilter,
        formatter: statusFormatter,
        className: 'text-center',
        onClick: (row, searchArray, page = 1, limit = 10) => handleChangeStatus(row, searchArray, page, limit),
      },
      {
        dataField: 'folhaPagamentoHumanReadable',
        text: 'Nº Fatura',
        textFilter: true,
        formatter: folhaPagamentoHumanReadableFormatter,
      },
      {
        dataField: 'situacao',
        text: 'Situação',
        selectFilter: situacaoFilter,
        formatter: situacaoFormatter,
        className: 'text-center',
        onClick: (row, searchArray, page = 1, limit = 10) => handleChangeSituacao(row, searchArray, page, limit),
      },
      {
        dataField: 'grupo',
        text: 'Grupo',
        textFilter: true,
        formatter: grupoFormatter,
      },
      {
        dataField: 'adminFaturamento',
        text: 'Responsável Faturamento',
        textFilter: true,
        formatter: adminFaturamentoFormatter,
        width: '100px',
      },
      {
        dataField: 'profissional',
        text: 'Especialista',
        textFilter: true,
        formatter: especialistaFormatter,
        width: '100px',
      },
      {
        dataField: 'especialidade',
        text: 'Especialidade',
        textFilter: true,
        formatter: especialidadeFormatter,
        width: '100px',
      },
      {
        dataField: 'name',
        text: 'Nome/Empresa',
        textFilter: true,
        formatter: nameFormatter,
        width: '100%',
      },
      {
        dataField: 'cpf',
        text: 'CPF/CNPJ',
        textFilter: true,
        formatter: cpfOrCnpjFormatter,
      },
      {
        dataField: 'qtdeHoras',
        text: 'Qtde. Horas',
        textFilter: false,
        formatter: horasFormatter,
      },
      {
        dataField: 'valor',
        text: 'Valor',
        moneyRangeFilter: true,
        formatter: (row) => numberToCurrency(row?.valor),
        width: '170px',
        editable: (row) => row.status === 'aberto',
        dataType: 'money',
        // onConfirm: (valor) => console.log(valor),
      },
      {
        dataField: 'dataFechamento',
        text: 'Fechamento',
        dateFilter: true,
        formatter: (row) => (row.dataFechamento ? format(parseISO(row.dataFechamento), 'dd/MM/yyyy') : 'Sem Fechamento'),
        width: '170px',
        editable: (row) => row.status === 'aberto',
        dataType: 'date',
      },
      {
        dataField: 'dataVencimento',
        text: 'Vencimento',
        dateFilter: true,
        formatter: (row) => (row.dataVencimento ? format(parseISO(row.dataVencimento), 'dd/MM/yyyy') : 'Sem Vencimento'),
        width: '170px',
        editable: (row) => row.status === 'aberto',
        dataType: 'date',
      },
      {
        dataField: 'dataEmissao',
        text: 'Emissão',
        dateFilter: true,
        formatter: (row) => (row.dataEmissao && row.status === 'emitido' ? format(parseISO(row.dataEmissao), 'dd/MM/yyyy HH:mm') : 'Não Emitido'),
        width: '170px',
      },
      {
        dataField: 'dataPagamento',
        text: 'Data de Pagamento',
        dateFilter: true,
        formatter: (row) => (row.dataPagamento && row.situacao === 'pago' ? format(parseISO(row.dataPagamento), 'dd/MM/yyyy HH:mm') : 'Em Aberto'),
        width: '170px',
      },
      {
        dataField: 'observacao',
        text: 'OBS',
        formatter: observacaoFormatter,
        width: '50px',
      },
    ],
    [],
  );

  return (
    <Container fluid>
      <AlertModal ref={alertRef} />
      <FinanceiroEdit ref={modalFinanceiroEdit} />
      <CustomModal ref={modal} />
      <CustomModalEmitirFatura ref={modalEmitirFatura} />
      <CustomModalFecharFatura ref={modalFecharFatura} />
      <CustomModalFecharFaturas ref={modalFecharFaturas} />
      <CustomModalPagarFaturas ref={modalPagarFaturas} />
      <CustomModalPagarFatura ref={modalPagarFatura} />
      <CustomModalMotivoCancelamento ref={modalMotivoCancelamento} />
      <Row className="mt-5">
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Financeiro</h3>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <Card color="danger">
                    <CardHeader tag="h4" className="text-white text-truncate">
                      Contas a Pagar
                    </CardHeader>
                    <CardBody className="bg-pink">
                      <div className="d-flex flex-wrap align-items-center">
                        <i className="fa-solid fa-money-bill-transfer text-white mr-3" style={{ fontSize: '1.75rem' }} />
                        <Fade>
                          <span className="text-white" style={{ fontSize: '2.25rem' }}>{numberToCurrency(totalContaPagar)}</span>
                        </Fade>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Button color="link" className="text-white text-decoration-none" onClick={() => navigate('/conta-pagar')} block>
                        Criar Conta a Pagar
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <Card color="success">
                    <CardHeader tag="h4" className="text-white text-truncate">
                      Contas a Receber
                    </CardHeader>
                    <CardBody>
                      <div className="d-flex flex-wrap align-items-center">
                        <i className="fa-solid fa-hand-holding-dollar text-white mr-3" style={{ fontSize: '1.75rem' }} />
                        <Fade>
                          <span className="text-white" style={{ fontSize: '2.25rem' }}>{numberToCurrency(totalContaReceber)}</span>
                        </Fade>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Button color="link" className="text-white text-decoration-none" onClick={() => navigate('/conta-receber')} block>
                        Criar Conta a Receber
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col xs={12} md={12} lg={4}>
                  <Card color="info">
                    <CardHeader tag="h4" className="text-white text-truncate">
                      Saldo
                    </CardHeader>
                    <CardBody>
                      <div className="d-flex flex-wrap align-items-center">
                        <i className={classNames('fa-solid fa-sack-dollar mr-3', { 'text-white': (totalContaReceber - totalContaPagar >= 0), 'text-danger': (totalContaReceber - totalContaPagar < 0) })} style={{ fontSize: '1.75rem' }} />
                        <Fade>
                          <span className={classNames({ 'text-white': (totalContaReceber - totalContaPagar >= 0), 'text-danger': (totalContaReceber - totalContaPagar < 0) })} style={{ fontSize: '2.25rem' }}>{numberToCurrency(totalContaReceber - totalContaPagar)}</span>
                        </Fade>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Button color="link" className="text-white text-decoration-none" style={{ cursor: 'default' }} onClick={() => null} block>
                        Saldo
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col tag="div" className="d-flex flex-column" style={{ paddingLeft: '2.25rem', width: '100%', maxWidth: '600px' }}>
          {/* <Fade>
            <Button
              color="danger"
              className="mr-2 mb-2"
              onClick={() => setShowCloseFinanceiroModal(true)}
            >
              Fechar Financeiro por Período
            </Button>
          </Fade> */}
          {/* {selectedRows.filter((selectedRow) => (selectedRow.status === 'aberto')).length ? (
            <Fade>
              <Button color="danger" className="mr-2 mb-2" onClick={handleFecharFaturaSelecionadas}>
                {`Fechar ${selectedRows.filter((selectedRow) => selectedRow.status === 'aberto').length} Fatura(s)`}
              </Button>
            </Fade>
          ) : null} */}
          {selectedRows.some((selectedRow) => (selectedRow.status === 'aberto')) && (
            <Button
              color="info"
              className={classNames('mr-2 mb-2')}
              onClick={handleFecharFaturaSelecionadas}
            >
              {/* {`Fechar Financeiro - ${selectedRows.filter((selectedRow) => (selectedRow.status === 'aberto')).length} financeiro(s) aberto(s)`} */}
              Fechar Financeiro em Lote
            </Button>
          )}
          {selectedRows.some((selectedRow) => (selectedRow.status === 'fechado')) && (
            <Button
              color="warning"
              className={classNames('mr-2 mb-2')}
              onClick={handleGerarFolhaPagamento}
            >
              {/* {`Gerar Folha de Pagamento - ${selectedRows.filter((selectedRow) => (selectedRow.status === 'fechado')).length} fatura(s) fechada(s)`} */}
              Gerar Folha de Pagamento em Lote
            </Button>
          )}
          {selectedRows.some((selectedRow) => (selectedRow.situacao !== 'pago')) && (
            <Button color="success" className={classNames('mr-2 mb-2')} onClick={handlePagarFaturaSelecionadas}>
              {/* {`Pagar ${selectedRows.filter((selectedRow) => (selectedRow.situacao !== 'pago')).length} fatura(s)`} */}
              Pagar Faturas em Lote
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <TableManager
            initialColumns={columns}
            onUpdate={(row, searchArray, page = 1, limit = 10) => modalFinanceiroEdit.current.show(row, { searchArray, page, limit }, () => load('/financeiros', 1, []))}
            onDelete={(row) => destroy(row._id, '/financeiros')}
            // onCreatePdf={handleCreatePdf}
            onCreateCsv={handleCreateCsv}
            onSelectedRows={(rows) => {
              console.log(rows);
              setSelectedRows(rows);
            }}
            showTotalFinance
            selectable
            selectableColumns
            enableDeletedItemsButton
          />
        </Col>
      </Row>
      <Modal
        isOpen={showCloseFinanceiroModal}
        toggle={() => setShowCloseFinanceiroModal(!showCloseFinanceiroModal)}
        centered
      >
        <ModalHeader>
          <strong>Fechar Financeiro por período</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowCloseFinanceiroModal(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={6}>
                <DatePickerMaskedInput
                  label="Data de"
                  placeholderText="Data de"
                  selected={dataCloseFinanceiroInicio}
                  invalidMessage={validationErrors.dataCloseFinanceiroInicio}
                  onChange={(date) => setDataCloseFinanceiroInicio(date)}
                />
              </Col>
              <Col xs={6}>
                <DatePickerMaskedInput
                  label="Data até"
                  placeholderText="Data até"
                  selected={dataCloseFinanceiroTermino}
                  invalidMessage={validationErrors.dataCloseFinanceiroTermino}
                  onChange={(date) => setDataCloseFinanceiroTermino(date)}
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleCloseFinanceiro}>Fechar Financeiro nesse período</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default Financeiros;
