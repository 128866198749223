/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { Input, FormFeedback } from 'reactstrap';

import PropTypes from 'prop-types';

import { normalizeTextForSearch } from '../../utils/functions';

function CustomSelectSearch({
  value,
  onChange,
  onRenderLabel,
  options,
  placeholder,
  invalid,
  errorMessage,
  isLoading,
  loadingMessage,
  noOptionsMessage,
  name,
  id,
  isMultiple,
  disabled,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const containerRef = useRef(null);

  const selectedOptions = isMultiple
    ? options.filter((option) => value?.includes(option.value))
    : options.find((option) => option.value === value);

  const filteredOptions = options.filter((option) => normalizeTextForSearch(option.label).includes(normalizeTextForSearch(searchTerm)));

  const handleSelect = (option) => {
    if (isMultiple) {
      const newValue = value ? [...value] : [];
      const index = newValue.indexOf(option.value);

      if (index === -1) {
        newValue.push(option.value);
      } else {
        newValue.splice(index, 1);
      }

      onChange(newValue);
    } else {
      onChange(option.value);
      setIsOpen(false);
    }
    setSearchTerm('');
  };

  const handleClearAll = (e) => {
    e.stopPropagation();
    onChange(isMultiple ? [] : '');
  };

  const handleClearOption = (optionValue, e) => {
    e.stopPropagation();
    if (isMultiple) {
      const newValue = value.filter((val) => val !== optionValue);
      onChange(newValue);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      <div
        onClick={() => !disabled && setIsOpen(!isOpen)}
        style={{
          border: `1px solid ${invalid ? '#dc3545' : '#ced4da'}`,
          borderRadius: '0.25rem',
          padding: '0.375rem 0.75rem',
          cursor: 'pointer',
          backgroundColor: 'white',
          minHeight: '38px',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '4px',
          ...(disabled && {
            opacity: 0.5,
            cursor: 'not-allowed',
          }),
        }}
      >
        {isMultiple ? (
          selectedOptions.length > 0 ? (
            <>
              {selectedOptions.map((option) => (
                <span
                  key={option.value}
                  style={{
                    backgroundColor: '#e9ecef',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  {option.label}
                  <span
                    onClick={(e) => handleClearOption(option.value, e)}
                    style={{
                      cursor: 'pointer',
                      color: '#666',
                      fontWeight: 'bold',
                    }}
                  >
                    ×
                  </span>
                </span>
              ))}
              <span
                onClick={handleClearAll}
                style={{
                  cursor: 'pointer',
                  color: '#666',
                  fontSize: '14px',
                  marginLeft: 'auto',
                }}
              >
                Limpar todos
              </span>
            </>
          ) : (
            <span style={{ color: '#6c757d' }}>{placeholder}</span>
          )
        ) : (
          <>
            <span style={{ flex: 1 }}>
              {selectedOptions ? selectedOptions.label : placeholder}
            </span>
            {selectedOptions && (
              <span
                onClick={handleClearAll}
                style={{
                  cursor: 'pointer',
                  color: '#666',
                  fontWeight: 'bold',
                }}
              >
                ×
              </span>
            )}
          </>
        )}
      </div>

      {invalid && <FormFeedback style={{ display: 'block' }}>{errorMessage}</FormFeedback>}

      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            backgroundColor: 'white',
            border: '1px solid #ced4da',
            borderRadius: '4px',
            zIndex: 1000,
            maxHeight: '300px',
            overflowY: 'auto',
            marginTop: '4px',
          }}
        >
          <Input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              border: 'none',
              borderBottom: '1px solid #ced4da',
              borderRadius: 0,
              padding: '8px',
            }}
            onClick={(e) => e.stopPropagation()}
            autoFocus
          />
          <div style={{ padding: '8px' }}>
            {isLoading ? (
              <div style={{ padding: '8px' }}>{loadingMessage}</div>
            ) : filteredOptions.length === 0 ? (
              <div style={{ padding: '8px' }}>{noOptionsMessage}</div>
            ) : (
              filteredOptions.map((option, index) => (
                <div
                  key={`${option.value}-${index}`}
                  onClick={() => handleSelect(option)}
                  style={{
                    padding: '8px',
                    cursor: 'pointer',
                    backgroundColor: isMultiple
                      ? value?.includes(option.value) ? '#f8f9fa' : 'transparent'
                      : option.value === value ? '#f8f9fa' : 'transparent',
                    '&:hover': {
                      backgroundColor: '#f8f9fa',
                    },
                  }}
                >
                  {onRenderLabel ? onRenderLabel(option) : option.label}
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}

CustomSelectSearch.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func.isRequired,
  onRenderLabel: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  isMultiple: PropTypes.bool,
};

CustomSelectSearch.defaultProps = {
  value: '',
  placeholder: 'Selecione uma opção',
  invalid: false,
  errorMessage: '',
  isLoading: false,
  loadingMessage: 'Carregando...',
  noOptionsMessage: 'Nenhuma opção encontrada',
  name: '',
  id: '',
  isMultiple: false,
};

export default CustomSelectSearch;
