/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, FormFeedback, Input, Label, Row } from 'reactstrap';

import { yupResolver } from '@hookform/resolvers/yup';
import { validateCPF } from 'validations-br';

import CustomMaskedInput from '../../components/CustomMaskedInput';
import CustomSelectSearch from '../../components/CustomSelectSearch';
import DatePickerMaskedInput from '../../components/DatePickerMaskedInput';
import MoneyMaskedInput from '../../components/MoneyMaskedInput';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';
import { schema } from './validationSchema';

const defaultValues = {
  contaReceberDe: 'cliente',
};

function ContaReceber() {
  const navigate = useNavigate();
  const { loading, setLoading, notificationRef } = useContext(AppContext);
  const [clientes, setClientes] = useState([]);
  const [profissionais, setProfissionais] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const { control, watch, setError, handleSubmit, formState: { errors }, setValue } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const watchContaReceberDe = watch('contaReceberDe');

  useEffect(() => {
    const loadProfissionais = async () => {
      const { data = [] } = await api.get('/profissionais?limit=0');
      return data;
    };

    const loadClientes = async () => {
      const { data = [] } = await api.get('/users?limit=0');
      return data;
    };

    const loadEspecialidades = async () => {
      const { data = [] } = await api.get('/especialidades?limit=0');
      return data;
    };

    (async () => {
      try {
        const [profissionaisData = [], clientesData = [], especialidadesData = []] = await Promise.all([loadProfissionais(), loadClientes(), loadEspecialidades()]);
        // console.log(profissionaisData, fornecedoresData);
        setProfissionais(profissionaisData.map((d) => ({
          value: d._id,
          label: d.name,
        })));

        setClientes(clientesData.map((d) => ({
          value: d._id,
          label: d.criancas.nome,
          cpf: d.criancas.cpf,
        })));

        setEspecialidades(especialidadesData.map((d) => ({
          value: d._id,
          label: d.nome,
          cor: d.cor,
        })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const resetFields = () => {
    setValue('contaReceberDe', 'cliente');
    setValue('cliente', '');
    setValue('profissional', '');
    setValue('nome', '');
    setValue('cpf', '');
    setValue('especialidade', '');
    setValue('horas', '');
    setValue('valor', '');
    setValue('dataVencimento', '');
    setValue('observacoes', '');
    window.location.reload();
  };

  const onSubmit = (formValues) => {
    const { contaReceberDe, cliente, nome, cpf, horas, valor, dataVencimento, observacoes, profissional, especialidade } = formValues;
    console.log(formValues);

    (async () => {
      try {
        if (contaReceberDe === 'cliente' && !cliente) {
          setError('cliente', { type: 'manual', message: 'Selecione um Paciente' });
          return;
        }

        if (contaReceberDe === 'profissional' && !profissional) {
          setError('profissional', { type: 'manual', message: 'Selecione um Profissional' });
          return;
        }

        if (contaReceberDe === 'cliente-avulso') {
          if (!nome) {
            setError('nome', { type: 'manual', message: 'Informe o nome do Cliente' });
            return;
          }

          if (!cpf) {
            setError('cpf', { type: 'manual', message: 'Informe o CPF do Cliente' });
            return;
          }

          if (!validateCPF(cpf)) {
            setError('cpf', { type: 'manual', message: 'CPF inválido' });
            return;
          }
        }

        setLoading(true);
        const data = {
          tipo: 'conta-receber',
          contaDe: contaReceberDe,
          horas,
          valor,
          dataVencimento,
          observacoes,
          especialidade,
          profissional,
        };

        const _cliente = clientes.find((c) => c.value === (cliente?.value || cliente));
        if (_cliente) {
          Object.assign(data, {
            cliente: {
              id: _cliente?.value,
              nome: _cliente?.label,
              cpf: _cliente?.cpf,
            },
          });
        } else {
          Object.assign(data, {
            cliente: {
              nome,
              cpf,
            },
          });
        }

        const _profissional = profissionais.find((p) => p.value === (profissional?.value || profissional));
        if (_profissional) {
          Object.assign(data, {
            profissional: _profissional?.value,
          });
        }

        console.log(data);
        await api.post('/financeiros', data);
        setLoading(false);
        notificationRef.current.notify({
          message: 'Conta a receber criado com sucesso',
        });

        /* setTimeout(() => {
          navigate('/financeiro');
        }, 1000); */

        resetFields();
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao cadastrar fatura',
          color: 'danger',
        });
      }
    })();
  };

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Cadastrar Conta a Receber</h3>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="mb-4">
                  <Col>
                    <Controller
                      name="contaReceberDe"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormGroup check>
                          <Input
                            type="radio"
                            value="cliente"
                            checked={value === 'cliente'}
                            onChange={onChange}
                          />
                          <Label check>Cliente</Label>
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <Controller
                      name="contaReceberDe"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormGroup check>
                          <Input
                            type="radio"
                            value="cliente-avulso"
                            checked={value === 'cliente-avulso'}
                            onChange={onChange}
                          />
                          <Label check>Cliente Avulso</Label>
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col>
                    <Controller
                      name="contaReceberDe"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormGroup check>
                          <Input
                            type="radio"
                            value="profissional"
                            checked={value === 'profissional'}
                            onChange={onChange}
                          />
                          <Label check>Profissional</Label>
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
                {watchContaReceberDe === 'cliente' ? (
                  <Row>
                    <Col>
                      <Controller
                        name="cliente"
                        control={control}
                        render={({ field: { onChange, name, value } }) => {
                          console.log(value);
                          return (
                            <FormGroup>
                              <Label for="clienteSelect">
                                Paciente
                              </Label>
                              <CustomSelectSearch
                                id="clienteSelect"
                                name={name}
                                value={value}
                                onChange={onChange}
                                options={clientes}
                                placeholder="Selecione um Paciente"
                                invalid={!!errors?.cliente?.message}
                                errorMessage={errors?.cliente?.message}
                                loadingMessage="Carregando pacientes"
                                noOptionsMessage="Nenhum paciente encontrado"
                              />
                            </FormGroup>
                          );
                        }}
                      />
                    </Col>
                  </Row>
                ) : watchContaReceberDe === 'profissional' ? (
                  <Row>
                    <Col>
                      <Controller
                        name="profissional"
                        control={control}
                        render={({ field: { onChange, name, value } }) => (
                          <FormGroup>
                            <Label for="profissionalSelect">
                              Profissional
                            </Label>
                            <CustomSelectSearch
                              id="profissionalSelect"
                              name={name}
                              value={value}
                              onChange={onChange}
                              options={profissionais}
                              placeholder="Selecione um Profissional"
                              invalid={!!errors?.profissional?.message}
                              errorMessage={errors?.profissional?.message}
                              loadingMessage="Carregando profissionais"
                              noOptionsMessage="Nenhum profissional encontrado"
                            />
                          </FormGroup>
                        )}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col>
                      <Controller
                        name="nome"
                        control={control}
                        render={({ field: { onChange, name, value } }) => (
                          <FormGroup>
                            <Label for="nome">Nome do Cliente</Label>
                            <Input
                              id="nome"
                              type="text"
                              placeholder="Nome do Cliente"
                              name={name}
                              value={value}
                              onChange={onChange}
                              invalid={!!errors?.nome?.message}
                            />
                            <FormFeedback>{errors?.nome?.message}</FormFeedback>
                          </FormGroup>
                        )}
                      />
                      <Controller
                        name="cpf"
                        control={control}
                        render={({ field: { onChange, name, value } }) => (
                          <CustomMaskedInput
                            id="cpf"
                            label="CPF"
                            placeholder="Informe um CPF"
                            format="###.###.###-##"
                            name={name}
                            value={value}
                            onValueChange={({ value: v }) => onChange(v)}
                            invalidMessage={errors?.cpf?.message}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                )}
                {watchContaReceberDe === 'cliente' && (
                  <Row>
                    <Col>
                      <Controller
                        name="profissional"
                        control={control}
                        render={({ field: { onChange, name, value } }) => (
                          <FormGroup>
                            <Label for="profissionalSelect">
                              Especialista
                            </Label>
                            <CustomSelectSearch
                              id="profissionalSelect"
                              name={name}
                              value={value}
                              onChange={onChange}
                              options={profissionais}
                              placeholder="Selecione um Especialista"
                              invalid={!!errors?.profissional?.message}
                              errorMessage={errors?.profissional?.message}
                              loadingMessage="Carregando especialistas"
                              noOptionsMessage="Nenhum especialista encontrado"
                              onRenderLabel={(option) => (
                                <div className="d-flex align-items-center">
                                  <div style={{ backgroundColor: option.cor, width: '20px', height: '20px', borderRadius: '10px', marginRight: '0.5rem' }} />
                                  {option.label}
                                </div>
                              )}
                            />
                          </FormGroup>
                        )}
                      />
                    </Col>
                  </Row>
                )}
                {watchContaReceberDe === 'profissional' && (
                  <Row>
                    <Col>
                      <Controller
                        name="cliente"
                        control={control}
                        render={({ field: { onChange, name, value } }) => {
                          console.log(value);
                          return (
                            <FormGroup>
                              <Label for="clienteSelect">
                                Paciente
                              </Label>
                              <CustomSelectSearch
                                id="clienteSelect"
                                name={name}
                                value={value}
                                onChange={onChange}
                                options={clientes}
                                placeholder="Selecione um Paciente"
                                invalid={!!errors?.cliente?.message}
                                errorMessage={errors?.cliente?.message}
                                loadingMessage="Carregando pacientes"
                                noOptionsMessage="Nenhum paciente encontrado"
                              />
                            </FormGroup>
                          );
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Controller
                      name="especialidade"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <FormGroup>
                          <Label for="especialidadeSelect">
                            Tipo de Atendimento
                          </Label>
                          <CustomSelectSearch
                            id="especialidadeSelect"
                            name={name}
                            value={value}
                            onChange={onChange}
                            options={especialidades}
                            placeholder="Selecione um Tipo de Atendimento"
                            invalid={!!errors?.especialidade?.message}
                            errorMessage={errors?.especialidade?.message}
                            loadingMessage="Carregando tipos de atendimento"
                            noOptionsMessage="Nenhum tipo de atendimento encontrado"
                            onRenderLabel={(option) => (
                              <div className="d-flex align-items-center">
                                <div style={{ backgroundColor: option.cor, width: '20px', height: '20px', borderRadius: '10px', marginRight: '0.5rem' }} />
                                {option.label}
                              </div>
                            )}
                          />
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Controller
                      name="horas"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <FormGroup>
                          <Label for="horas">Horas</Label>
                          <Input
                            id="horas"
                            type="time"
                            placeholder="Horas"
                            name={name}
                            value={value}
                            onChange={onChange}
                            invalid={!!errors?.horas?.message}
                          />
                          <FormFeedback>{errors?.horas?.message}</FormFeedback>
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Controller
                      name="valor"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <MoneyMaskedInput
                          label="Valor"
                          name={name}
                          invalidMessage={errors?.valor?.message}
                          value={value}
                          onValueChange={({ floatValue }) => onChange(floatValue)}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Controller
                      name="dataVencimento"
                      control={control}
                      render={({ field }) => (
                        <DatePickerMaskedInput
                          label="Data do Vencimento"
                          invalidMessage={errors?.dataVencimento?.message}
                          onChange={(e) => field.onChange(e)}
                          selected={field.value}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Controller
                      name="observacoes"
                      control={control}
                      render={({ field }) => (
                        <FormGroup>
                          <Label>Observações</Label>
                          <Input
                            type="textarea"
                            {...field}
                          />
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12} sm={6}>
            <Button type="submit" color="success" disabled={loading} block>Cadastrar</Button>
          </Col>
          <Col xs={12} sm={6}>
            <Button color="danger" disabled={loading} onClick={() => navigate(-1)} block>Cancelar</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default ContaReceber;
