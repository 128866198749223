import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import AppProvider from './app/contexts/app';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById('root'),
);

serviceWorker.register();
