/* eslint-disable max-len */
import React, { useState, useCallback, useRef, useContext } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';

import { isBefore, isEqual, subDays } from 'date-fns';

import DatePickerMaskedInput from '../../components/DatePickerMaskedInput';
import Notification from '../../components/Notification';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function Relatorios() {
  const notificationRef = useRef();
  const { setLoading } = useContext(AppContext);
  const [financeiroStartDate, setFinanceiroStartDate] = useState(new Date());
  const [financeiroEndDate, setFinanceiroEndDate] = useState(new Date());
  const [userStartDate, setUserStartDate] = useState('');
  const [userEndDate, setUserEndDate] = useState('');
  const [professionalStartDate, setProfessionalStartDate] = useState('');
  const [professionalEndDate, setProfessionalEndDate] = useState('');

  const handleFinanceiroGenerateReportCsv = useCallback(() => {
    if (!financeiroStartDate && !financeiroEndDate) {
      notificationRef.current.notify({
        message: 'Nenhuma data informada para gerar relatório',
        color: 'warning',
      });
      return;
    }

    if (!isEqual(financeiroStartDate, financeiroEndDate) && isBefore(financeiroEndDate, financeiroStartDate)) {
      notificationRef.current.notify({
        message: 'Data de início do período não pode ser maior que a data de fim do período',
        color: 'warning',
      });
      return;
    }

    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Aguarde... Estamos gerando o arquivo CSV... ',
        });

        // await fetch api
        const res = await api.get(`/financeiroCSVCreatorReports?startDate=${financeiroStartDate}&endDate=${financeiroEndDate}`, { responseType: 'blob' });
        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        if (err && err.response && err.response.status === 404) {
          notificationRef.current.notify({
            message: 'Nenhum registro encontrado para gerar o relatório',
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar ao gerar relatório',
          color: 'danger',
        });
      }
    })();
  }, [setLoading, financeiroStartDate, financeiroEndDate]);

  const handleUserGenerateReportCsv = useCallback(() => {
    if (userStartDate && userEndDate) {
      if (!isEqual(userStartDate, userEndDate) && isBefore(userEndDate, userStartDate)) {
        notificationRef.current.notify({
          message: 'Data de início do período não pode ser maior que a data de fim do período',
          color: 'warning',
        });
        return;
      }
    }

    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Aguarde... Estamos gerando o arquivo CSV... ',
        });

        // await fetch api
        const res = await api.get(`/userCSVCreatorReports?startDate=${userStartDate}&endDate=${userEndDate}`, { responseType: 'blob' });
        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        if (err && err.response && err.response.status === 404) {
          notificationRef.current.notify({
            message: 'Nenhum cliente encontrado com os dados informados, para gerar o relatório',
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar ao gerar relatório',
          color: 'danger',
        });
      }
    })();
  }, [notificationRef, setLoading, userStartDate, userEndDate]);

  const handleProfessionalGenerateReportCsv = useCallback(() => {
    if (professionalStartDate && professionalEndDate) {
      if (!isEqual(professionalStartDate, professionalEndDate) && isBefore(professionalEndDate, professionalStartDate)) {
        notificationRef.current.notify({
          message: 'Data de início do período não pode ser maior que a data de fim do período',
          color: 'warning',
        });
        return;
      }
    }

    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Aguarde... Estamos gerando o arquivo CSV... ',
        });

        // await fetch api
        const res = await api.get(`/profissionalCSVCreatorReportLists?startDate=${professionalStartDate}&endDate=${professionalEndDate}`, { responseType: 'blob' });
        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        if (err && err.response && err.response.status === 404) {
          notificationRef.current.notify({
            message: 'Nenhum profissional encontrado com os dados informados, para gerar o relatório',
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar ao gerar relatório',
          color: 'danger',
        });
      }
    })();
  }, [notificationRef, setLoading, professionalStartDate, professionalEndDate]);

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col>
          <Notification ref={notificationRef} />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Relatórios</h3>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <h5>Financeiro Consolidado GERAL</h5>
            </CardHeader>
            <CardBody>
              <Row className="mb-2">
                <Col>
                  <Button
                    color="primary"
                    onClick={() => {
                      setFinanceiroStartDate(subDays(new Date(), 15));
                      setFinanceiroEndDate(new Date());
                    }}
                  >
                    Últimos 15 dias
                  </Button>
                  <Button
                    className="ml-2"
                    color="primary"
                    onClick={() => {
                      setFinanceiroStartDate(subDays(new Date(), 30));
                      setFinanceiroEndDate(new Date());
                    }}
                  >
                    Últimos 30 dias
                  </Button>
                  <Button
                    className="ml-2"
                    color="primary"
                    onClick={() => {
                      setFinanceiroStartDate(subDays(new Date(), 60));
                      setFinanceiroEndDate(new Date());
                    }}
                  >
                    Últimos 60 dias
                  </Button>
                  <Button
                    className="ml-2"
                    color="primary"
                    onClick={() => {
                      setFinanceiroStartDate(subDays(new Date(), 90));
                      setFinanceiroEndDate(new Date());
                    }}
                  >
                    Últimos 90 dias
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} lg={4}>
                  <DatePickerMaskedInput
                    label="Início do Período"
                    placeholderText="Início do Período"
                    selected={financeiroStartDate}
                    onChange={(date) => setFinanceiroStartDate(date)}
                  />
                </Col>
                <Col xs={12} sm={6} lg={4}>
                  <DatePickerMaskedInput
                    label="Fim do Período"
                    placeholderText="Fim do Período"
                    selected={financeiroEndDate}
                    onChange={(date) => setFinanceiroEndDate(date)}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Button
                    color="primary"
                    onClick={handleFinanceiroGenerateReportCsv}
                  >
                    Gerar Relatório
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <Card>
            <CardHeader>
              <h5>Clientes</h5>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs={12} sm={6} lg={4}>
                  <DatePickerMaskedInput
                    label="Data de Cadastro - Início"
                    placeholderText="Data de Cadastro - Início"
                    selected={userStartDate}
                    onChange={(date) => setUserStartDate(date)}
                  />
                </Col>
                <Col xs={12} sm={6} lg={4}>
                  <DatePickerMaskedInput
                    label="Data de Cadastro - Fim"
                    placeholderText="Data de Cadastro - Fim"
                    selected={userEndDate}
                    onChange={(date) => setUserEndDate(date)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  Obs.: Para gerar relatório de todos os clientes, deixe os campos de data de cadastro em branco.
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Button
                    color="primary"
                    onClick={handleUserGenerateReportCsv}
                  >
                    Gerar Relatório
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <Card>
            <CardHeader>
              <h5>Profissionais</h5>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs={12} sm={6} lg={4}>
                  <DatePickerMaskedInput
                    label="Data de Cadastro - Início"
                    placeholderText="Data de Cadastro - Início"
                    selected={professionalStartDate}
                    onChange={(date) => setProfessionalStartDate(date)}
                  />
                </Col>
                <Col xs={12} sm={6} lg={4}>
                  <DatePickerMaskedInput
                    label="Data de Cadastro - Fim"
                    placeholderText="Data de Cadastro - Fim"
                    selected={professionalEndDate}
                    onChange={(date) => setProfessionalEndDate(date)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  Obs.: Para gerar relatório de todos os profissionais, deixe os campos de data de cadastro em branco.
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Button
                    color="primary"
                    onClick={handleProfessionalGenerateReportCsv}
                  >
                    Gerar Relatório
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Relatorios;
