import React from 'react';

import { useAuth } from '../hooks/auth';
import ManagerProvider from '../hooks/manager';
import AppAdminRoutes from './app.admin.routes';
import AuthRoutes from './auth.routes';

function AppRoutes() {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return (
      <ManagerProvider>
        <AppAdminRoutes />
      </ManagerProvider>
    );
  }

  return (
    <AuthRoutes />
  );
}

export default AppRoutes;
