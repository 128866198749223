/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import AuthProvider from './app/hooks/auth';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';
import 'animate.css';
import './app/assets/vendor/fontawesome-free-5.15.2-web/css/all.min.css';
import './app/assets/vendor/fontawesome-free-6.2.0-web/css/all.min.css';
import './app/assets/css/bootstrap.css';
import './app/assets/css/bootstrap9.css';
import './app/assets/css/custom.css';
import AppRoutes from './app/routes';

const loading = (
  <div
    className="bg-primary opacity-7 d-flex w-100 vh-100 align-items-center justify-content-center position-fixed left-0 top-0"
    style={{ zIndex: 999999 }}
  >
    <Spinner type="grow">
      Carregando...
    </Spinner>
  </div>
);

function App() {
  return (
    <Router>
      <Suspense fallback={loading}>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </Suspense>
    </Router>
  );
}

export default App;
