import React from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import InputMask from 'react-input-mask';
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { getMonth, getYear } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { range } from 'lodash';

import './styles.css';

registerLocale('ptBR', ptBR);
setDefaultLocale('ptBR');

function DatePickerMaskedInput({
  label,
  value,
  selected,
  title = 'Data',
  inputSize = 'md',
  placeholderText = 'dd/mm/aaaa',
  dateFormat = 'dd/MM/yyyy',
  locale = 'ptBR',
  invalidMessage = '',
  yearsRange = range(1900, getYear(new Date()) + 100, 1),
  onChange,
  withFormGroup = true,
  ...props
}) {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const handleClear = () => {
    onChange(null);
  };

  return (
    <div style={{ position: 'relative' }}>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              color="link"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <i className="fas fa-arrow-circle-left" />
            </Button>

            <Input
              className="mr-2"
              type="select"
              value={months[getMonth(date)]}
              onChange={({ target: { value: v } }) => changeMonth(months.indexOf(v))}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>

            <Input
              type="select"
              value={getYear(date)}
              onChange={({ target: { value: v } }) => changeYear(v)}
            >
              {yearsRange.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>

            <Button
              color="link"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <i className="fas fa-arrow-circle-right" />
            </Button>
          </div>
        )}
        title={title}
        placeholderText={placeholderText}
        dateFormat={dateFormat}
        locale={locale}
        selected={selected}
        value={value}
        onChange={onChange}
        customInput={(
          <InputMask
            mask="99/99/9999"
            placeholder="dd/mm/aaaa"
          >
            {(inputProps) => {
              if (withFormGroup) {
                return (
                  <FormGroup style={{ position: 'relative' }}>
                    {label ? (
                      <Label for={`date-picker-${props.id}`}>{label}</Label>
                    ) : null}
                    <Input
                      id={`date-picker-${props.id}`}
                      className="w-100"
                      bsSize={inputSize}
                      invalid={!!invalidMessage}
                      disabled={props.disabled}
                      {...inputProps}
                    />
                    <Button
                      color="light"
                      onClick={handleClear}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '72%',
                        transform: 'translateY(-50%)',
                        padding: '0 5px',
                      }}
                    >
                      &times;
                    </Button>
                    <FormFeedback className="text-danger">
                      {invalidMessage}
                    </FormFeedback>
                  </FormGroup>
                );
              }

              return (
                <div style={{ position: 'relative' }}>
                  <Input
                    id={`date-picker-${props.id}`}
                    className="w-100"
                    bsSize={inputSize}
                    invalid={!!invalidMessage}
                    disabled={props.disabled}
                    {...inputProps}
                  />
                  <Button
                    color="light"
                    onClick={handleClear}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '72%',
                      transform: 'translateY(-50%)',
                      padding: '0 5px',
                    }}
                  >
                    &times;
                  </Button>
                </div>
              );
            }}
          </InputMask>
        )}
        {...props}
      />
    </div>
  );
}

export default DatePickerMaskedInput;
