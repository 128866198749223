export const numberToCurrency = (number) => {
  if (number === null || number === undefined) return '';

  return number.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
};

export const adjustValue = (value, percentage, decimalPlaces = 0) => {
  const valueAdjusted = value * (1 + (percentage / 100));
  const valueRounded = Math.round(valueAdjusted * 10 ** decimalPlaces) / 10 ** decimalPlaces;
  return valueRounded;
};

export const normalizeTextForSearch = (text) => {
  if (!text) return '';
  if (typeof text !== 'string') return '';
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '');
};
