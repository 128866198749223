/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState, useCallback, useMemo, useRef, useContext } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import classNames from 'classnames';
import * as Yup from 'yup';

import AlertModal from '../../components/AlertModal';
import CustomButton from '../../components/CustomButton';
import TipoDeAtendimento from '../../components/Especialidade';
import { MoneyFormatter } from '../../components/Formatters';
import Notification from '../../components/Notification';
import Select from '../../components/Select';
import TableManager from '../../components/TableManager';
import { AppContext } from '../../contexts/app';
import { useManager } from '../../hooks/manager';
import api from '../../services/api';
import { adjustValue } from '../../utils/functions';

function TabelaValoresCliente() {
  const notificationRef = useRef();
  const especialidadeRef = useRef();
  const alertRef = useRef();
  const { loading, setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});
  const { load, destroy, update, store } = useManager();
  const [showModal, setShowModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updatedItemNotSaved, setUpdatedItemNotSaved] = useState(false);
  const [id, setId] = useState({});
  const [nome, setNome] = useState('');
  const [newEspecialidade, setNewEspecialidade] = useState('');
  const [especialidade, setEspecialidade] = useState('');
  const [especialidades, setEspecialidades] = useState([]);
  const [, setEspecialidadesList] = useState([]);
  const [valores, setValores] = useState([]);
  const [valor, setValor] = useState('');
  const [percentualReajuste, setPercentualReajuste] = useState(null);
  const [dataReajuste, setDataReajuste] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    load('/tabelaValoresClientes', 1, []);
  }, [load]);

  useEffect(() => {
    (async () => {
      try {
        const { data = [] } = await api.get('/especialidades');
        setEspecialidadesList(data);
        setEspecialidades(data.map((e) => ({
          value: e._id,
          label: e.nome,
        })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (newEspecialidade) {
      setEspecialidadesList((prevEspecialidadesList) => [newEspecialidade, ...prevEspecialidadesList]);
      setEspecialidades((prevEspecialidades) => [{ value: newEspecialidade._id, label: newEspecialidade.nome }, ...prevEspecialidades]);
      setEspecialidade({
        value: newEspecialidade._id,
        label: newEspecialidade.nome,
      });
      setNewEspecialidade('');
    }
  }, [newEspecialidade]);

  const handleClose = useCallback(() => {
    if (updatedItemNotSaved) {
      alertRef.current.show({
        title: 'Fechar sem Salvar',
        message: 'Você alterou informações que não foram salvas. Deseja realmente fechar sem salvar?',
        onConfirm: () => {
          alertRef.current.close();
          setShowModal(false);
        },
      });
      return;
    }

    setShowModal(false);
  }, [updatedItemNotSaved]);

  const handleUpdate = useCallback((data) => {
    setValidationErrors({});
    setUpdating(false);
    setUpdatedItemNotSaved(false);
    setId(data._id);
    setNome(data.nome);
    setEspecialidade('');
    setValor('');
    setValores(
      data?.valores?.map((v) => ({
        especialidade: {
          value: v.especialidade._id,
          label: v.especialidade.nome,
        },
        valor: v.valor,
      })) || [],
    );
    setEspecialidadesList((prevEspecialidadesList) => {
      setEspecialidades(
        data?.valores?.length
          ? prevEspecialidadesList
            .filter((e) => !data.valores.find((v) => e._id === v.especialidade._id))
            .map((e) => ({
              value: e._id,
              label: e.nome,
            }))
          : prevEspecialidadesList.map((e) => ({
            value: e._id,
            label: e.nome,
          })),
      );
      return prevEspecialidadesList;
    });
    setShowModal(true);
  }, []);

  const handleCreate = useCallback(() => {
    setValidationErrors({});
    setId(null);
    setNome('');
    setEspecialidade('');
    setEspecialidadesList((prevEspecialidadesList) => {
      setEspecialidades(
        prevEspecialidadesList.map((e) => ({
          value: e._id,
          label: e.nome,
        })),
      );
      return prevEspecialidadesList;
    });
    setValor('');
    setValores([]);
    setShowModal(true);
  }, []);

  const handleAddValores = useCallback(() => {
    (async () => {
      try {
        const data = {
          especialidade: especialidade?.value ? especialidade.value : '',
          valor,
        };

        const schema = Yup.object().shape({
          especialidade: Yup.string().required('Selecione um tipo de atendimento'),
          valor: Yup.string().required('Informe um valor'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setEspecialidade('');
        setValor('');
        setValidationErrors({});
        setEspecialidades((prevEspecialidades = []) => prevEspecialidades.filter((e) => e.value !== especialidade.value));
        setValores((prevValores) => [...prevValores, { especialidade, valor }]);
        setUpdating(false);
      } catch (err) {
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message:
              'Por favor, preencha os dados necessários',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
        }
      }
    })();
  }, [especialidade, valor]);

  const handleUpdateValores = useCallback((especialidadeData, valorData) => {
    setEspecialidades((prevEspecialidades = []) => [...prevEspecialidades, especialidadeData].sort((a, b) => (a.label > b.label ? 1 : -1)));
    setValores((prevValores = []) => prevValores.filter((v) => v.especialidade.value !== especialidadeData.value));
    setEspecialidade(especialidadeData);
    setValor(valorData);
    setUpdating(true);
    setUpdatedItemNotSaved(true);
  }, []);

  const handleRemoveValores = useCallback((especialidadeData) => {
    setEspecialidades((prevEspecialidades = []) => [...prevEspecialidades, especialidadeData].sort((a, b) => (a.label > b.label ? 1 : -1)));
    setValores((prevValores = []) => prevValores.filter((v) => v.especialidade.value !== especialidadeData.value));
  }, []);

  const handleCreateCsv = useCallback((_selectedRows = []) => {
    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Aguarde... Estamos gerando o arquivo CSV... ',
        });

        // await fetch api
        const res = await api.get(`/tabelaValorClienteCSVCreatorReportLists?tabelaValoresClienteIds=${JSON.stringify(_selectedRows.map(({ _id }) => _id))}`, { responseType: 'blob' });
        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar ao gerar relatório',
          color: 'danger',
        });
      }
    })();
  }, [setLoading]);

  const handleConfirmarReajuste = useCallback(() => {
    (async () => {
      try {
        setValidationErrors({});
        const data = {
          percentual: percentualReajuste === '' ? undefined : Number(percentualReajuste),
          data: dataReajuste || undefined,
          tabelaValoresClienteId: id,
        };
        console.log(data);

        const schema = Yup.object().shape({
          percentual: Yup.number()
            .typeError('Informe o percentual de reajuste')
            .min(0.01, 'O percentual de reajuste deve ser maior que 0')
            .required('Informe o percentual de reajuste'),
          data: Yup.date()
            .typeError('Data inválida')
            .required('Informe a data do reajuste'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setUpdating(true);
        setLoading({
          loading: true,
          message: 'Aguarde... confirmando o reajuste... ',
        });
        const res = await api.post('/tabelaValorReajustes', data);
        console.log(res.data);

        if (res?.data?.tabelaValorUpdated?.valores?.length) {
          setValores((prevValores) => {
            console.log('🚀 ~ setValores ~ prevValores:', prevValores);
            const newValores = prevValores.map((v1) => {
              const vUpdated = res.data.tabelaValorUpdated.valores.find((v2) => {
                console.log(v2.especialidade, v1.especialidade.value, v2.especialidade === v1.especialidade.value);
                return v2.especialidade === v1.especialidade.value;
              });
              return {
                ...v1,
                valor: vUpdated.valor,
              };
            });
            console.log(newValores);
            return newValores;
          });
        }

        setUpdating(false);
        setLoading(false);
        notificationRef.current.notify({
          message: 'Reajuste confirmado com sucesso',
          color: 'success',
        });
      } catch (err) {
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Por favor, preencha todos os dados necessários para confirmar o reajuste',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Algo inesperado aconteceu e não foi possível confirmar o reajuste',
          color: 'danger',
        });
      }
    })();
  }, [percentualReajuste, dataReajuste, id]);

  const handleSave = async () => {
    try {
      const data = {
        nome,
        valores: valores.map((v) => ({
          especialidade: v.especialidade.value,
          valor: `${v.valor}`,
        })),
      };

      const schema = Yup.object().shape({
        nome: Yup.string().required('Informe o nome da tabela de valores clientes'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (!valores.length) {
        notificationRef.current.notify({
          message: 'Informe a(s) especialidade(s) e seu valor',
          color: 'warning',
        });
        return;
      }

      if (id) {
        update(id, '/tabelaValoresClientes', data, () => {
          setLoading(false);
          setShowModal(false);
          setUpdatedItemNotSaved(false);
        }, () => setLoading(false));
        return;
      }

      store('/tabelaValoresClientes', data, () => {
        setLoading(false);
        setShowModal(false);
        setUpdatedItemNotSaved(false);
      }, () => setLoading(false));
    } catch (err) {
      setLoading(false);
      console.log(err);
      setValidationErrors({});
      // Validation failed
      if (err instanceof Yup.ValidationError) {
        notificationRef.current.notify({
          message: 'Por favor, preencha todos os dados necessários para salvar',
          color: 'warning',
        });
        const ve = {};
        err.inner.forEach((e) => {
          ve[e.path] = e.message;
        });
        setValidationErrors(ve);
        return;
      }

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  };

  const columns = useMemo(() => [
    {
      dataField: 'nome',
      text: 'Nome',
      textFilter: true,
    },
  ]);

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col>
          <AlertModal ref={alertRef} />
          <TipoDeAtendimento ref={especialidadeRef} onSave={setNewEspecialidade} />
          <Notification ref={notificationRef} />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Tabela de Valores para Clientes</h3>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Row>
                    <Col xs={12} sm={5} md={3} className="mb-3 mb-xl-0">
                      <Button block color="success" onClick={handleCreate}>
                        Cadastrar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableManager
            initialColumns={columns}
            onCreateCsv={handleCreateCsv}
            onUpdate={(row) => handleUpdate(row)}
            onDelete={(row) => destroy(row._id, '/tabelaValoresClientes')}
            selectable
          />
        </Col>
      </Row>
      <Modal
        isOpen={showModal}
        fullscreen
      >
        <ModalHeader>
          <strong>Tabela de Valores para Clientes</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={handleClose}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Input
                type="text"
                placeholder="Nome da Tabela"
                value={nome}
                invalid={!!validationErrors.nome}
                onChange={({ target: { value } }) => setNome(value)}
              />
              <FormFeedback>{validationErrors.nome}</FormFeedback>
            </FormGroup>
            <Row>
              <Col xs={12} lg={5}>
                <Row>
                  <Col xs={3}>
                    <FormGroup>
                      <Input
                        type="number"
                        placeholder="Percentual de reajuste"
                        value={percentualReajuste || ''}
                        onChange={({ target: { value } }) => setPercentualReajuste(value === '' ? null : value)}
                        invalid={!!validationErrors.percentualReajuste}
                      />
                      <FormFeedback>{validationErrors.percentualReajuste}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs={3}>
                    <FormGroup>
                      <Input
                        type="date"
                        value={dataReajuste || ''}
                        onChange={({ target: { value } }) => setDataReajuste(value || null)}
                        invalid={!!validationErrors.dataReajuste}
                      />
                      <FormFeedback>{validationErrors.dataReajuste}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs={3}>
                    <UncontrolledTooltip target="confirmar-reajuste">
                      Confirmar reajuste
                    </UncontrolledTooltip>
                    <Button
                      id="confirmar-reajuste"
                      color="success"
                      className="text-white"
                      disabled={updating}
                      onClick={() => handleConfirmarReajuste()}
                      block
                    >
                      <i className="fa-solid fa-circle-plus text-white mr-2" />
                      Confirmar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={5}>
                <Row>
                  <Col xs={9}>
                    <Select
                      placeholder="Selecione um tipo de atendimento"
                      value={especialidade}
                      options={especialidades}
                      invalidMessage={validationErrors.especialidade}
                      onChange={setEspecialidade}
                      disabled={updating}
                    />
                  </Col>
                  <Col xs={3}>
                    <UncontrolledTooltip target="new-especialidade">
                      {updating ? 'Termine a alteração para cadastrar um tipo de atendimento' : 'Cadastrar um tipo de atendimento'}
                    </UncontrolledTooltip>
                    <Button
                      id="new-especialidade"
                      color="success"
                      disabled={updating}
                      onClick={() => especialidadeRef.current.show(null, false)}
                      block
                    >
                      <i className="fa-solid fa-circle-plus text-white" />
                      <i className="fa-solid fa-layer-group ml-2 text-white" />
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={4}>
                <FormGroup>
                  <NumericFormat
                    customInput={Input}
                    placeholder="Digite o valor"
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    prefix="R$"
                    value={valor}
                    invalid={!!validationErrors.valor}
                    fixedDecimalScale
                    onValueChange={({ floatValue }) => setValor(floatValue)}
                  />
                  <FormFeedback>{validationErrors.valor}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={12} lg={3}>
                <Button color={updating ? 'info' : 'success'} onClick={handleAddValores} block>
                  <i className={classNames('fa-solid text-white', { 'fa-circle-plus': !updating, 'fa-edit': update })} />
                  {updating ? (
                    <span className="text-white ml-2">Alterar</span>
                  ) : (
                    <span className="text-white ml-2">Adicionar</span>
                  )}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {valores.length ? (
                  valores
                    .sort((a, b) => (a.especialidade.label > b.especialidade.label ? 1 : -1))
                    .map((v, index) => (
                      <Row className="mb-2" key={`valores-${index}`}>
                        <Col xs={6}>{v.especialidade.label}</Col>
                        <Col xs={4}>
                          <MoneyFormatter value={parseFloat(v.valor)} />
                          { percentualReajuste && (
                            <span className="text-muted ml-2">
                              &rarr;
                              {' ( '}
                              <MoneyFormatter className="ml-2" value={adjustValue(parseFloat(v.valor), percentualReajuste, 0)} />
                              {' )'}
                            </span>
                          )}
                        </Col>
                        <Col xs={2} tag="div" className="d-flex justify-content-end">
                          <UncontrolledTooltip target={`edit-button-${index}`}>
                            {updating ? 'Termine a alteração para alterar outro tipo de atendimento' : 'Alterar'}
                          </UncontrolledTooltip>
                          <Button
                            id={`edit-button-${index}`}
                            color="info"
                            className="mr-2"
                            disabled={updating}
                            onClick={() => handleUpdateValores(v.especialidade, v.valor)}
                          >
                            <i className="fa-solid fa-edit text-white" />
                          </Button>
                          <UncontrolledTooltip target={`delete-button-${index}`}>
                            Excluir
                          </UncontrolledTooltip>
                          <Button
                            id={`delete-button-${index}`}
                            color="danger"
                            onClick={() => handleRemoveValores(v.especialidade)}
                          >
                            <i className="fa-solid fa-trash-can text-white" />
                          </Button>
                        </Col>
                      </Row>
                    ))
                ) : (
                  <p className="text-center">Cadastre os valores</p>
                )}
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            color="success"
            onClick={handleSave}
            loadingMessage={updating ? 'Termine a alteração para Salvar' : 'Salvando...'}
            loading={updating ? true : loading?.loading ? loading.loading : loading}
          >
            Salvar
          </CustomButton>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default TabelaValoresCliente;
